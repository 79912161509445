.dk-accdn .acc-title button, .accordion > h6:first-child, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal]), .accordion > h5:first-child, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal]), .accordion > h4:first-child, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal]), .accordion > h3:first-child, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal]), .accordion > h2:first-child, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal]) {
  background-color: #f2f2f2;
  cursor: default;
}
.dk-accdn .acc-title button::after, .accordion > h6:first-child::after, .accordion > h6:first-child ~ h6:not(.literal):not([data-literal])::after, .accordion > h5:first-child::after, .accordion > h5:first-child ~ h5:not(.literal):not([data-literal])::after, .accordion > h4:first-child::after, .accordion > h4:first-child ~ h4:not(.literal):not([data-literal])::after, .accordion > h3:first-child::after, .accordion > h3:first-child ~ h3:not(.literal):not([data-literal])::after, .accordion > h2:first-child::after, .accordion > h2:first-child ~ h2:not(.literal):not([data-literal])::after {
  content: normal !important;
}
.dk-accdn .acc-title button:hover, .accordion > h6:hover:first-child, .accordion > h6:first-child ~ h6:hover:not(.literal):not([data-literal]), .accordion > h5:hover:first-child, .accordion > h5:first-child ~ h5:hover:not(.literal):not([data-literal]), .accordion > h4:hover:first-child, .accordion > h4:first-child ~ h4:hover:not(.literal):not([data-literal]), .accordion > h3:hover:first-child, .accordion > h3:first-child ~ h3:hover:not(.literal):not([data-literal]), .accordion > h2:hover:first-child, .accordion > h2:first-child ~ h2:hover:not(.literal):not([data-literal]) {
  background-color: #f2f2f2 !important;
}

.accordion > * {
  display: block !important;
}
.accordion.accordion:not([data-no-expand-all]):not(.off) {
  margin-top: 1rem;
}

.dk-accdn .acc-ctrl {
  display: none;
}

.dk-accdn .acc-content {
  display: block !important;
}

.expandable::before {
  content: normal !important;
}
.expandable > * {
  display: block !important;
}

.foldable .fold ~ *, .box .fold ~ * {
  display: block !important;
}
.foldable .fold, .box .fold, .foldable .folded, .box .folded {
  display: none !important;
}

.dk-tabnav .tn-drop-button {
  display: none;
}
.dk-tabnav > ul {
  position: static;
}
.dk-tabnav > ul::before {
  display: none;
}
.dk-tabnav > ul.tn-expandable > li > div button {
  display: none;
}
@media screen and (max-width: 1024px) {
  .dk-tabnav:not(.tn-menu-open) > ul {
    display: block;
  }
}